import Api from '../library/Api';
import QuestionnaireVariableService from './QuestionnaireVariableService';
import Session from '../valueobject/Session';
import Tracker from '../library/Tracker';
import countryMap from '../valueobject/countryMap';
import { IWindow } from '../interface/IWindow';
import { emitWindowEvent } from '../utils/emitWindowEvent';
import { questionnaireVariables, sessionLayerRequester, trackerEventNames, windowEvents } from '../library/Constants';
import { utils } from '../utils/Utils';
import { IFetchOptions } from 'interface/IFetchOptions';

declare let window: IWindow;

export interface CustomerProfileData {
  last_lead_created_at?: string;
  days_since_last_lead?: number;
  last_gid?: string;
  last_lead_status?: string;
  returning_lead: boolean;
  customer_gid?: string;
  phone_number?: string;
}

export interface PhoneConsentData {
  sms_consent_given: boolean;
  whatsapp_consent_given: boolean;
}

class CustomerDataService {
  private static readonly BASE_ENDPOINT = utils.getResourcePath('customerDataService');

  private static readonly ENDPOINT_CUSTOMER_PROFILE = `${CustomerDataService.BASE_ENDPOINT}/profile/customer-profile`;
  private static readonly ENDPOINT_PHONE_CONSENT = `${CustomerDataService.BASE_ENDPOINT}/phone-consent`;

  private api: Api;
  private locale: string;
  private questionnaireVariableService: QuestionnaireVariableService;
  private session: Session;
  private tracker: Tracker;

  public static ERRORS = {
    invalidPhoneNumber: 'Invalid phone number',
    unsupportedLocale: 'Unsupported locale',
    missingPhoneNumber: 'Missing phone number',
    missingGid: 'Missing GID',
    missingRelationGid: 'Missing relation GID',
    failedToFetch: 'Failed to fetch',
    failedToFetchProfileData: 'Failed to fetch customer profile data'
  };

  constructor(
    api: Api,
    locale: string,
    questionnaireVariableService: QuestionnaireVariableService,
    session: Session,
    tracker: Tracker
  ) {
    this.api = api;
    this.locale = locale;
    this.questionnaireVariableService = questionnaireVariableService;
    this.session = session;
    this.tracker = tracker;
  }

  public async fetchCustomerProfile(phone: string): Promise<CustomerProfileData> {
    const method = 'fetchCustomerProfile';

    this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_REQUEST_STARTED, {
      method,
      gid: this.session.getGid(),
      url: this.session.getUrl(),
      locale: this.locale,
      phone
    });

    if (!this.isSupportedLocale(this.locale)) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_UNSUPPORTED_LOCALE, {
        locale: this.locale,
        method,
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.unsupportedLocale));
    }

    if (!phone) {
      return Promise.reject(new Error(CustomerDataService.ERRORS.missingPhoneNumber));
    }

    let formattedPhone = null;

    if (!window.libphonenumber || !this.locale) {
      const message = this.locale
        ? trackerEventNames.LIBPHONENUMBER_NOT_AVAILABLE
        : trackerEventNames.LOCALE_NOT_AVAILABLE;

      this.tracker.track(message, {
        locale: this.locale,
        method,
        phone: phone
      });

      formattedPhone = phone;
    } else {
      formattedPhone = this.validateAndFormatPhone(phone, this.locale);
    }

    if (!formattedPhone) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_INVALID_PHONE_NUMBER, {
        locale: this.locale,
        method,
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.invalidPhoneNumber));
    }

    try {
      const customerProfileData: CustomerProfileData = await this.fetchCustomerProfileData(formattedPhone);

      this.questionnaireVariableService.setReserved(
        questionnaireVariables.USER_CPS_DAYS_SINCE_LAST_LEAD,
        customerProfileData.days_since_last_lead
      );
      this.questionnaireVariableService.setReserved(
        questionnaireVariables.USER_CPS_LAST_GID,
        customerProfileData.last_gid
      );
      this.questionnaireVariableService.setReserved(
        questionnaireVariables.USER_CPS_LAST_LEAD_STATUS,
        customerProfileData.last_lead_status
      );
      this.questionnaireVariableService.setReserved(
        questionnaireVariables.USER_CPS_RETURNING_LEAD,
        customerProfileData.returning_lead
      );

      emitWindowEvent(windowEvents.customerDataService.CUSTOMER_PROFILE_FETCHED, customerProfileData);

      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_REQUEST_SUCCESS, {
        method,
        locale: this.locale,
        gid: this.session.getGid(),
        url: this.session.getUrl(),
        hasData: !!customerProfileData,
        returningLead: customerProfileData.returning_lead
      });

      return Promise.resolve(customerProfileData);
    } catch (err) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_FETCH_FAILED, {
        error: err,
        locale: this.locale,
        gid: this.session.getGid(),
        url: this.session.getUrl(),
        method,
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.failedToFetch));
    }
  }

  public async fetchCustomerProfileByGid(gid: string): Promise<CustomerProfileData> {
    const method = 'fetchCustomerProfileByGid';

    if (!gid) {
      return Promise.reject(new Error(CustomerDataService.ERRORS.missingGid));
    }

    try {
      const customerProfileData: CustomerProfileData = await this.fetchCustomerProfileDataByGid(gid);
      return Promise.resolve(customerProfileData);
    } catch (err) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_FETCH_FAILED, {
        error: err,
        locale: this.locale,
        method,
        gid: gid
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.failedToFetch));
    }
  }

  public async fetchCustomerProfileByRelationGid(gid: string): Promise<CustomerProfileData> {
    const method = 'fetchCustomerProfileByRelationGid';

    if (!gid) {
      return Promise.reject(new Error(CustomerDataService.ERRORS.missingRelationGid));
    }

    try {
      const customerProfileData: CustomerProfileData = await this.fetchCustomerProfileDataByRelationGid(gid);
      return Promise.resolve(customerProfileData);
    } catch (err) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_FETCH_FAILED, {
        error: err,
        locale: this.locale,
        method,
        relationGid: gid
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.failedToFetch));
    }
  }

  public async fetchPhoneConsent(phone: string): Promise<PhoneConsentData> {
    const method = 'fetchPhoneConsent';

    if (!this.isSupportedLocale(this.locale)) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_UNSUPPORTED_LOCALE, {
        locale: this.locale,
        method,
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.unsupportedLocale));
    }

    if (!phone) {
      return Promise.reject(new Error(CustomerDataService.ERRORS.missingPhoneNumber));
    }

    let formattedPhone = null;

    if (!window.libphonenumber || !this.locale) {
      const message = this.locale
        ? trackerEventNames.LIBPHONENUMBER_NOT_AVAILABLE
        : trackerEventNames.LOCALE_NOT_AVAILABLE;

      this.tracker.track(message, {
        locale: this.locale,
        method,
        phone: phone
      });

      formattedPhone = phone;
    } else {
      formattedPhone = this.validateAndFormatPhone(phone, this.locale);
    }

    if (!formattedPhone) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_INVALID_PHONE_NUMBER, {
        locale: this.locale,
        method,
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.invalidPhoneNumber));
    }

    try {
      const phoneConsentData: PhoneConsentData = await this.fetchPhoneConsentData(formattedPhone);

      this.questionnaireVariableService.setReserved(
        questionnaireVariables.USER_SMS_CONSENT_GIVEN,
        phoneConsentData.sms_consent_given ? 'Yes' : 'No'
      );
      this.questionnaireVariableService.setReserved(
        questionnaireVariables.USER_WHATSAPP_CONSENT_GIVEN,
        phoneConsentData.whatsapp_consent_given ? 'Yes' : 'No'
      );

      emitWindowEvent(windowEvents.customerDataService.PHONE_CONSENT_FETCHED, phoneConsentData);

      return Promise.resolve(phoneConsentData);
    } catch (err) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_FETCH_FAILED, {
        error: err,
        locale: this.locale,
        method,
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.failedToFetch));
    }
  }

  private validateAndFormatPhone(phone: string, locale: string): string | null {
    const method = 'validateAndFormatPhone';

    try {
      const countryCodeIsoAlpha2 = this.localeToCountryCodeIsoAlpha2(locale);
      const parsedPhone = window.libphonenumber.parsePhoneNumberFromString(phone, countryCodeIsoAlpha2);

      if (!parsedPhone.isValid()) {
        this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_PHONE_NUMBER_INVALID, {
          locale: locale,
          method,
          phone: phone
        });

        return null;
      }

      const formattedPhone = parsedPhone.format('E.164');

      if (!formattedPhone) {
        return null;
      }

      return formattedPhone;
    } catch (err) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_PHONE_NUMBER_VALIDATION_FAILED, {
        error: err,
        locale: locale,
        method,
        phone: phone
      });

      return null;
    }
  }

  private localeToCountryCodeIsoAlpha2(locale: string): string {
    return locale.slice(3);
  }

  private localeToCountryCodeIsoAlpha3(locale: string): string {
    return countryMap[locale] || null;
  }

  private isSupportedLocale(locale: string): boolean {
    return !!countryMap[locale];
  }

  private async fetchCustomerProfileData(phone: string): Promise<CustomerProfileData> {
    const payload = {
      country: this.localeToCountryCodeIsoAlpha3(this.locale),
      gid: this.session.getGid(),
      locale: this.locale,
      phone,
      requester: sessionLayerRequester,
      url: this.session.getUrl()
    };

    try {
      const response = await this.api.post(CustomerDataService.ENDPOINT_CUSTOMER_PROFILE, payload);

      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_API_SUCCESS, {
        method: 'fetchCustomerProfileData',
        endpoint: CustomerDataService.ENDPOINT_CUSTOMER_PROFILE,
        gid: this.session.getGid(),
        locale: this.locale,
        phone,
        requester: sessionLayerRequester,
        url: this.session.getUrl()
      });

      return response;
    } catch (error) {
      this.tracker.track(trackerEventNames.CUSTOMER_DATA_SERVICE_API_FAILED, {
        error,
        locale: this.locale,
        gid: this.session.getGid(),
        url: this.session.getUrl(),
        method: 'fetchCustomerProfileData',
        phone: phone
      });

      return Promise.reject(new Error(CustomerDataService.ERRORS.failedToFetchProfileData));
    }
  }

  private async fetchPhoneConsentData(phone: string): Promise<PhoneConsentData> {
    const payload = {
      country: this.localeToCountryCodeIsoAlpha3(this.locale),
      gid: this.session.getGid(),
      locale: this.locale,
      phone,
      requester: sessionLayerRequester,
      url: this.session.getUrl()
    };
    const headers = {};
    const options: IFetchOptions = {
      retryAttempts: 3,
      retryDelay: 1000
    };

    return this.api.post(CustomerDataService.ENDPOINT_PHONE_CONSENT, payload, headers, options);
  }

  private async fetchCustomerProfileDataByGid(gid: string): Promise<CustomerProfileData> {
    const payload = {
      gid: gid,
      requester: sessionLayerRequester,
      url: this.session.getUrl()
    };

    return this.api.post(CustomerDataService.ENDPOINT_CUSTOMER_PROFILE, payload);
  }

  private async fetchCustomerProfileDataByRelationGid(relationGid: string): Promise<CustomerProfileData> {
    const payload = {
      relationGid,
      gid: this.session.getGid(),
      requester: sessionLayerRequester,
      url: this.session.getUrl()
    };

    return this.api.post(CustomerDataService.ENDPOINT_CUSTOMER_PROFILE, payload);
  }
}

export default CustomerDataService;
