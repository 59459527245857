export const sessionLayerRequester = 'SessionLayer';

export const urlParameter = {
  ACT: 'act',
  AUD_P: 'aud_p',
  DISABLE_REACTIVATION_DELAY: 'disableReactivationDelay',
  FBCLID: 'fbclid',
  GCLID: 'gclid',
  GEO: 'geo',
  IP_ADDRESS: 'ipaddress',
  LEAD_GID: 'LeadCustomerGID',
  LEAD_ID: 'LeadID',
  SESSION_GID: 'SessionGID',
  MKT_TOOL_ID: 'mkt_tool_id',
  RECOMMENDED_BY: 'project',
  REFERRER_GID: 'referrerGID',
  SFMC_JOBID: 'sfmc_jobid',
  SFMC_JSBID: 'sfmc_jsbid',
  SFMC_SUBID: 'sfmc_subid',
  SFMC_SUBSCRIBER_KEY: 'sfmc_subscriberkey',
  STATE: 'state',
  TBCLID: 'tbclid',
  TEST: 't',
  UPSERT: 'upsert',
  UTM_CONTENT: 'utm_content',
  UTM_JOURNEY: 'utm_journey',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_PUBLISHER_ID: 'utm_publisher_ID',
  UTM_SECTION_ID: 'utm_section_ID',
  UTM_TERM: 'utm_term',
  VARIATION_ID: 'varid',
  VISITOR_ID: 'audid'
};

export const leadKeys = {
  ACT: 'act__c',
  AUD_P: 'aud_p',
  BROWSER: 'Browser__c',
  CLOSING_SERVICE_STATUS: 'Lead_Closing_Service_Closing_Result__c',
  CONTROLLING_CHANNEL: 'Controlling_Channel__c',
  DISABLE_MERGE_DELAY: 'disableMergeDelay',
  EMAIL: 'Email',
  GID: 'GID__c',
  INITIAL_OFFER: 'Initial_Offer__c',
  IP_ADDRESS: 'IP_Address__c',
  LANDING_URL: 'Landing_URL__c',
  LATITUDE: 'Latitude',
  LEAD_CUSTOMER_GID: 'LeadCustomerGID',
  LEAD_SOURCE: 'LeadSource',
  LEAD_URL: 'Lead_URL__c',
  LOCALE: 'Locale__c',
  LONGITUDE: 'Longitude',
  MARKETING_OFFER_V2: 'Marketing_Offer_V2__c',
  MARKETING_PARTNER: 'Marketing_Partner__c',
  OM_TEST_ID: 'OM_Test_ID__c',
  PHONE: 'Phone',
  SELECTED_CAMPAIGN: 'Selected_Campaign__m',
  SFMC_SUBSCRIBER_KEY: 'sfmc_subscriberkey',
  STATUS: 'Status',
  UPSERT: 'upsert',
  USAGE: 'Usage__c',
  USER_DEVICE: 'User_Device__c',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  UTM_TERM: 'utm_term',
  VAR_ID: 'varid'
};

export const domSelector = {
  CLIENT_INJECTOR: '.ClientInjector',
  FORM_CLIENT_INJECTOR: '.FormClientInjector',
  SESSION_LAYER: '#SessionLayer'
};

export const cookieBannerKey = {
  MARKETING_SALES_TRACKING: 'Marketing Sales Tracking',
  TYPE: 'type'
};

export const leadResponseKey = {
  ABSOLUTE_SCORE: 'absolute_score',
  LEAD_CLOSING_STATUS: 'leadclosing_status',
  RANKED_CUSTOMER_JOURNEY: 'Ranked_Customer_Journey__c'
};

export const localStorageKey = {
  GID: 'lead-gid',
  LEAD_CREATION_TIME: 'lead-creation-time',
  LEAD_ID: 'lead-id',
  REACTIVATION_GID: 'reactivation-gid',
  REACTIVATION_TIME: 'reactivation-time',
  REACTIVATION_GID_GENERATED_AT: 'reactivation-gid-generated-at',
  VISITOR_TIMESTAMP: 'visitor-timestamp'
};

export const sessionStorageKey = {
  SESSION_GID: 'session-gid',
  VISITOR_TIMESTAMP: 'visitor-timestamp'
};

export const questionnaireVariables = {
  DEVICE_OS: 'device_os',
  DEVICE_TYPE: 'device_type',
  MARKETING_ACT: 'marketing_act',
  MARKETING_BRAND: 'marketing_brand',
  MARKETING_CHANNEL: 'marketing_channel',
  MARKETING_OFFER: 'marketing_offer',
  MARKETING_PARTNER: 'marketing_partner',
  MARKETING_USAGE: 'marketing_usage',
  USER_CPS_DAYS_SINCE_LAST_LEAD: 'user_cpsDaysSinceLastLead',
  USER_CPS_LAST_GID: 'user_cpsLastGid',
  USER_CPS_LAST_LEAD_STATUS: 'user_cpsLastLeadStatus',
  USER_CPS_RETURNING_LEAD: 'user_cpsReturningLead',
  USER_PHONE_CONSENT_GIVEN: 'user_phoneConsentGiven',
  USER_RETURNING_LEAD: 'user_returningLead',
  USER_RETURNING_VISITOR: 'user_returningVisitor',
  USER_SMS_CONSENT_GIVEN: 'user_smsConsentGiven',
  USER_WHATSAPP_CONSENT_GIVEN: 'user_whatsappConsentGiven'
};

export const windowEvents = {
  customerDataService: {
    CUSTOMER_PROFILE_FETCHED: 'customerProfileFetched',
    PHONE_CONSENT_FETCHED: 'phoneConsentFetched'
  },
  lead: {
    REACTIVATED: 'leadReactivated'
  },
  questionnaireVariables: {
    INITIALISED: 'questionnaireVariablesInitialised',
    UPDATED: 'questionnaireVariablesUpdated'
  },
  sessionLayer: {
    REACTIVATION_READY: 'sessionLayerReactivationReady',
    READY: 'sessionLayerReady'
  }
};

export const emitterEvents = {
  DATA_PUSHED: 'dataPushed',
  LEAD_CREATED: 'leadCreated'
};

export const trackerEventNames = {
  CAMPAIGN_SERVICE_REQUEST_FAILED: 'CAMPAIGN_SERVICE_REQUEST_FAILED',
  CAMPAIGN_SERVICE_USING_DEFAULT_ACT: 'CAMPAIGN_SERVICE_USING_DEFAULT_ACT',
  CAMPAIGN_SERVICE_REQUEST_SUCCESS: 'CAMPAIGN_SERVICE_REQUEST_SUCCESS',
  CUSTOMER_DATA_SERVICE_REQUEST_STARTED: 'CUSTOMER_DATA_SERVICE_REQUEST_STARTED',
  CUSTOMER_DATA_SERVICE_REQUEST_SUCCESS: 'CUSTOMER_DATA_SERVICE_REQUEST_SUCCESS',
  CUSTOMER_DATA_SERVICE_API_SUCCESS: 'CUSTOMER_DATA_SERVICE_API_SUCCESS',
  CUSTOMER_DATA_SERVICE_FETCH_FAILED: 'CUSTOMER_DATA_SERVICE_FETCH_FAILED',
  CUSTOMER_DATA_SERVICE_API_FAILED: 'CUSTOMER_DATA_SERVICE_API_FAILED',
  CUSTOMER_DATA_SERVICE_INVALID_PHONE_NUMBER: 'CUSTOMER_DATA_SERVICE_INVALID_PHONE_NUMBER',
  CUSTOMER_DATA_SERVICE_PHONE_NUMBER_INVALID: 'CUSTOMER_DATA_SERVICE_PHONE_NUMBER_INVALID',
  CUSTOMER_DATA_SERVICE_PHONE_NUMBER_VALIDATION_FAILED: 'CUSTOMER_DATA_SERVICE_PHONE_NUMBER_VALIDATION_FAILED',
  CUSTOMER_DATA_SERVICE_UNSUPPORTED_LOCALE: 'CUSTOMER_DATA_SERVICE_UNSUPPORTED_LOCALE',
  LEAD_CHUNK_BUFFER_FAILED: 'LEAD_CHUNK_BUFFER_FAILED',
  LEAD_CHUNK_BUFFERED: 'LEAD_CHUNK_BUFFERED',
  LEAD_CLOSING_SERVICE_REQUEST_FAILED: 'LEAD_CLOSING_SERVICE_REQUEST_FAILED',
  LEAD_CLOSING_STATUS_ATTACHED: 'LEAD_CLOSING_STATUS_ATTACHED',
  LEAD_CLOSING_STATUS_FAILED: 'LEAD_CLOSING_STATUS_FAILED',
  LEAD_CLOSING_STATUS_PUSHED_GTM: 'LEAD_CLOSING_STATUS_PUSHED_GTM',
  LEAD_CLOSING_STATUS_STARTED: 'LEAD_CLOSING_STATUS_STARTED',
  LEAD_CREATE_SUCCEEDED: 'LEAD_CREATE_SUCCEEDED',
  LEAD_SUBMIT_FAILED: 'LEAD_SUBMIT_FAILED',
  LEAD_SUBMIT_STARTED: 'LEAD_SUBMIT_STARTED',
  LEAD_SUBMITTING_TO_LEADHANDLER: 'LEAD_SUBMITTING_TO_LEADHANDLER',
  LEAD_SUBMITTING_TO_LEADHANDLER_FAILED: 'LEAD_SUBMITTING_TO_LEADHANDLER_FAILED',
  LEAD_SUBMITTING_TO_LEADHANDLER_SUCCESS: 'LEAD_SUBMITTING_TO_LEADHANDLER_SUCCESS',
  LEAD_UPDATE_SUCCEEDED: 'LEAD_UPDATE_SUCCEEDED',
  LIBPHONENUMBER_NOT_AVAILABLE: 'LIBPHONENUMBER_NOT_AVAILABLE',
  LOCALE_NOT_AVAILABLE: 'LOCALE_NOT_AVAILABLE',
  MARKETING_SERVICE_REQUEST_FAILED: 'MARKETING_SERVICE_REQUEST_FAILED',
  MARKETING_SERVICE_REQUEST_SUCCESS: 'MARKETING_SERVICE_REQUEST_SUCCESS',
  REACTIVATION_STARTED: 'REACTIVATION_STARTED',
  REACTIVATION_FAILED: 'REACTIVATION_FAILED',
  REACTIVATION_INVALID: 'REACTIVATION_INVALID',
  REACTIVATION_OVERLAP: 'REACTIVATION_OVERLAP',
  REACTIVATION_GID_FAILED: 'REACTIVATION_GID_FAILED',
  REACTIVATION_LOCALSTORAGE_FAILED: 'REACTIVATION_LOCALSTORAGE_FAILED',
  REACTIVATION_COMPLETED: 'REACTIVATION_COMPLETED',
  REQUEST_FAILED: 'REQUEST_FAILED',
  WEB_STORAGE_NOT_AVAILABLE: 'WEB_STORAGE_NOT_AVAILABLE'
};
